.rc-input-number {
  margin: 0;
  padding: 0;
  line-height: 26px;
  font-size: 12px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  transition: all 0.3s;
}
.rc-input-number-focused {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.rc-input-number-handler {
  text-align: center;
  line-height: 12px;
  height: 12px;
  overflow: hidden;
  display: block;
  touch-action: none;
}
.rc-input-number-handler-active {
  background: #ddd;
}
.rc-input-number-handler-up-inner,
.rc-input-number-handler-down-inner {
  color: #666666;
  user-select: none;
  -webkit-user-select: none;
}
.rc-input-number:hover {
  border-color: #1890ff;
}
.rc-input-number:hover .rc-input-number-handler-up,
.rc-input-number:hover .rc-input-number-handler-wrap {
  border-color: #1890ff;
}
.rc-input-number-disabled:hover {
  border-color: #d9d9d9;
}
.rc-input-number-disabled:hover .rc-input-number-handler-up,
.rc-input-number-disabled:hover .rc-input-number-handler-wrap {
  border-color: #d9d9d9;
}
.rc-input-number-input-wrap {
  overflow: hidden;
  height: 100%;
}
.rc-input-number-input {
  width: 100%;
  text-align: center;
  outline: 0;
  -moz-appearance: textfield;
  line-height: 26px;
  height: 100%;
  transition: all 0.3s ease;
  color: #666666;
  border: 0;
  border-radius: 4px;
  padding: 0;
  transition: all 0.3s;
}
.rc-input-number-handler-wrap {
  float: right;
  border-left: 1px solid #D9D9D9;
  width: 20px;
  height: 100%;
  transition: all 0.3s;
}
.rc-input-number-handler-up {
  border-bottom: 1px solid #D9D9D9;
  padding-top: 1px;
  transition: all 0.3s;
}
.rc-input-number-handler-up-inner:after {
  content: '+';
}
.rc-input-number-handler-down {
  transition: all 0.3s;
}
.rc-input-number-handler-down-inner:after {
  content: '-';
}
.rc-input-number-handler-down-disabled,
.rc-input-number-handler-up-disabled {
  opacity: 0.72;
}
.rc-input-number-handler-down-disabled:hover,
.rc-input-number-handler-up-disabled:hover {
  color: #999;
  border-color: #d9d9d9;
}
.rc-input-number-disabled .rc-input-number-input {
  opacity: 0.72;
  cursor: not-allowed;
  background-color: #f3f3f3;
}
.rc-input-number-disabled .rc-input-number-handler {
  opacity: 0.72;
}
.rc-input-number-disabled .rc-input-number-handler:hover {
  color: #999;
  border-color: #d9d9d9;
}
